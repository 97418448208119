export const isFutureDate = (dateString: string): boolean => {
  const selectedDate = new Date(dateString);
  const now = new Date();
  return selectedDate > now;
};

export const dateIsWithinNextMonth = (dateString: string): boolean => {
  const selectedDate = new Date(dateString);
  const now = new Date();

  const oneMonthAhead = new Date(now);
  oneMonthAhead.setMonth(now.getMonth() + 1);

  return selectedDate > now && selectedDate <= oneMonthAhead;
};
