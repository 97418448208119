import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Progress.css";
import { useTranslation } from "react-i18next";
import { Flex, Text, Image, Popup, InfoIcon, AcceptIcon, Alert, Label, Tooltip } from "@fluentui/react-northstar";
import getTrainingObjectives from "../../../../utils/helpers/getTrainingObjectives";
import getAllTrainings from "../../../../utils/helpers/getAllTrainings";
import { ICombinedTraining } from "../../../../utils/types/profile";
import getAllTrainingDetails from "../../../../utils/helpers/getAllTrainingDetails";
import { TrainingCatalogueProps } from "../../../../utils/types/progress";
import { TrainingType } from "../../../../utils/types/common";
import { useCompanyData } from "../../../../utils/context/companyContext";
import { dateIsWithinNextMonth } from "../../../../utils/helpers/checkDate";

function TrainingCatalogue({ mandatoryOnly, learningInfo, learningObjectives, setTrainingToLaunch, noMandatoryTrainings }: TrainingCatalogueProps) {
  const { t } = useTranslation();
  const { companyData } = useCompanyData();
  const [learningObjectivesMap, setLearningObjectivesMap] = useState<Map<string, string[]> | undefined>();
  const isTrial = process.env.REACT_APP_ENV_TYPE === "trial";
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const lpExpiryDate = learningInfo?.chosenLearningPathTrainingExpiryDate;

  useEffect(() => {
    if (learningObjectives) {
      const trainingObjectives = getTrainingObjectives(learningObjectives);
      setLearningObjectivesMap(trainingObjectives);
    } else {
      setLearningObjectivesMap(undefined);
    }
  }, [learningObjectives]);

  const executeScroll = () => {
    const target = scrollRef.current;
    if (target) {
      target.parentElement!.scrollTop = target.offsetTop - target.parentElement!.offsetTop;
    }
  };

  const { ccTrainings, customTrainings } = getAllTrainings(learningInfo);

  // sort traingings to show completed on top
  const compareTrainingStatus = (a: ICombinedTraining, b: ICombinedTraining) => {
    return a.completed === b.completed ? 0 : a.completed ? -1 : 1;
  };
  // filter trainings for mandatoryOnly list and remove duplicates
  const filteredTrainings = ccTrainings?.filter((training) => (mandatoryOnly ? training.mandatory : true)) ?? [];

  const allTrainingsArray = customTrainings.length ? [...filteredTrainings, ...customTrainings] : filteredTrainings;
  const sortedTrainings = allTrainingsArray.sort(compareTrainingStatus);

  // find next noncompleted training for scroll effect
  const nextTrainingIndex = sortedTrainings.findIndex((training) => !training.completed || (training?.completed && training?.expired));

  const trainingRows = sortedTrainings.map((training, index) => {
    const { trainingName, trainingDialog, imageUrl, trainingType } = getAllTrainingDetails(training);
    const trainingExpiring = training?.completed && lpExpiryDate && dateIsWithinNextMonth(lpExpiryDate);
    const trainingCompleted = training?.completed && !training?.expired;

    const learningObjectiveList = learningObjectivesMap
      ? (learningObjectivesMap.get(trainingDialog) || []).map((objective, idx) => (
          <Text key={trainingDialog + "-LO-" + idx} styles={{ marginLeft: "1em" }} content={" - " + objective} />
        ))
      : [];

    const refProps = index === nextTrainingIndex ? { ref: scrollRef } : {};
    const trainingToLaunchContent = {
      shortname: trainingDialog,
      name: trainingName,
      type: trainingType,
    };
    const trainingRowStyles = trainingCompleted ? (trainingExpiring ? "trainingExpiring" : "trainingCompleted") : "";
    return (
      <Flex
        key={trainingDialog}
        space="between"
        vAlign="center"
        className={`trainingRow ${trainingRowStyles}`}
        styles={{
          borderStyle: index === nextTrainingIndex ? "solid" : "none",
        }}
        {...refProps}
        onClick={() => !isTrial && setTrainingToLaunch(trainingToLaunchContent)}
      >
        <Flex gap="gap.small" vAlign="center">
          <Image fluid src={imageUrl} styles={{ width: "2rem" }} />
          <Text size="medium" content={trainingName} />
        </Flex>
        <Flex gap="gap.smaller" vAlign="center">
          {trainingCompleted && !trainingExpiring && <AcceptIcon />}
          {trainingExpiring && (
            <Tooltip
              trigger={<Label content={`${t("Profile.Trainings.expiring")} `} className="expiringLabel" />}
              content={`${t("Profile.Trainings.completionExpiring")} ${new Date(lpExpiryDate).toLocaleDateString()}`}
            />
          )}
          {learningObjectiveList?.length > 0 && (
            <Popup
              on="hover"
              pointing
              mouseLeaveDelay={0}
              content={
                <Flex column gap="gap.smaller" vAlign="center">
                  <Text weight="bold" content={`${t("Profile.LearningPath.objectivesOf", { training: trainingName })}:`} />
                  {learningObjectiveList}
                </Flex>
              }
              trigger={<InfoIcon outline alt="Click for details" xSpacing="before" />}
            />
          )}
          {/* show "content created by x" company if training is from custom content */}
          {trainingType === TrainingType.CUSTOM && (
            <Popup
              on="hover"
              pointing
              mouseLeaveDelay={0}
              content={
                <Flex column gap="gap.smaller" vAlign="center">
                  <Text content={`${t("Profile.CustomContent.subTitle", { companyName: companyData?.companyName })}`} />
                </Flex>
              }
              trigger={<InfoIcon outline alt="Click for details" xSpacing="before" />}
            />
          )}
        </Flex>
      </Flex>
    );
  });

  useLayoutEffect(() => {
    if (nextTrainingIndex !== -1) {
      executeScroll();
    }
  }, [trainingRows, nextTrainingIndex]);

  return (
    <Flex column gap="gap.small" className="trainingCatalogueContainer">
      {trainingRows && trainingRows.length > 0 ? (
        trainingRows
      ) : (
        <Alert content={noMandatoryTrainings ? t("Profile.LearningPath.emptyMandatoryTrainings") : t("Profile.LearningPath.emptyTrainingRow")} />
      )}
    </Flex>
  );
}

export default TrainingCatalogue;
