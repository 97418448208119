import React from "react";
import "./Badges.css";
import { useTranslation } from "react-i18next";
import { Grid, Text, Image, Tooltip, Flex, ExclamationTriangleIcon } from "@fluentui/react-northstar";
import { BadgeElementProps } from "../../../../utils/types/profile";
import getAllTrainingDetails from "../../../../utils/helpers/getAllTrainingDetails";
import { TrainingType } from "../../../../utils/types/common";
import { useCompanyData } from "../../../../utils/context/companyContext";
import { dateIsWithinNextMonth, isFutureDate } from "../../../../utils/helpers/checkDate";

function BadgeElement({ trainings, setTrainingToLaunch, lpExpiryDate }: BadgeElementProps) {
  const { t } = useTranslation();
  const { companyData } = useCompanyData();
  const isTrial = process.env.REACT_APP_ENV_TYPE === "trial";
  const longLearningPath = trainings && trainings?.length > 30;
  const gridLength = trainings && trainings?.length > 15 ? 4 : 3;

  return (
    <Grid className="badgeElementGrid" columns={longLearningPath ? 5 : gridLength}>
      {trainings?.map((training) => {
        const { trainingName, trainingDialog, imageUrl, trainingType } = getAllTrainingDetails(training);
        const trainingToLaunchContent = {
          shortname: trainingDialog,
          name: trainingName,
          type: trainingType,
        };
        // training is really completed when completion is not expired
        const trainingCompleted = training?.completed && !training?.expired;
        // check if lpExpiryDate is one month in future
        const trainingExpiringSoon = trainingCompleted && lpExpiryDate && dateIsWithinNextMonth(lpExpiryDate);
        // check if lpExiryDate is in the future and show for completed trainings
        const trainingExpiring = trainingCompleted && lpExpiryDate && isFutureDate(lpExpiryDate);
        // check if training was expired in the past
        const trainingExpired = training?.completed && training?.expired && lpExpiryDate;

        const completedBadge = training?.mandatory ? "completedBadgeMandatory" : "";
        const unCompleted = training?.mandatory ? "unCompletedBadgeMandatory" : "unCompletedBadge";
        const expiringTraining = training?.mandatory ? "expiringTrainingMandatory" : "expiringTraining";

        return (
          <Tooltip
            key={trainingName}
            trigger={
              <div className="badgeImageContent">
                <Image
                  src={imageUrl}
                  fluid
                  className={`badgeBase ${trainingCompleted ? (trainingExpiringSoon ? expiringTraining : completedBadge) : unCompleted}`}
                  onClick={() => !isTrial && setTrainingToLaunch(trainingToLaunchContent)}
                />
                {trainingExpiringSoon && <ExclamationTriangleIcon size="large" className="expiryIcon" />}
              </div>
            }
            content={
              <Flex column>
                <Text content={trainingName} />
                <Text
                  content={training?.completedOn ? t("Profile.Badges.completedOn") + ": " + new Date(training?.completedOn).toLocaleDateString() : ""}
                />
                {/* show when training is going to expire */}
                {trainingExpiring && (
                  <Text content={`${t("Profile.Trainings.completionExpiring")}: ${new Date(lpExpiryDate).toLocaleDateString()}`} />
                )}
                {/* show when training was expired */}
                {trainingExpired && <Text content={`${t("Profile.Trainings.completionExpired")}: ${new Date(lpExpiryDate).toLocaleDateString()}`} />}
                {trainingType === TrainingType.CUSTOM && (
                  <Text content={t("Profile.CustomContent.subTitle", { companyName: companyData?.companyName })} />
                )}
              </Flex>
            }
          />
        );
      })}
    </Grid>
  );
}

export default BadgeElement;
