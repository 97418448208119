import React, { useContext, useState, createContext } from "react";
import { UserContextType, IUserData } from "../types/user";

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserData = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<IUserData | undefined | null>(null);
  const [isReportAdmin, setIsReportAdmin] = useState<boolean>(false);

  return <UserContext.Provider value={{ userData, setUserData, isReportAdmin, setIsReportAdmin }}>{children}</UserContext.Provider>;
};

export default UserProvider;
