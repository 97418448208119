import React, { useState, useRef } from "react";
import { Card, Flex, Header, Text, Checkbox, Loader, Alert } from "@fluentui/react-northstar";
import "../../Profile.css";
import { useTranslation } from "react-i18next";
import TrainingCatalogue from "./TrainingCatalogue";
import StartTrainingDialog from "../../../elements/StartTrainingDialog";
import useScreenSize from "../../../../utils/hooks/useScreenSize";
import useGetLearningPathNumbers from "../../../../utils/hooks/useGetLearningPathNumbers";
import { ProgressBar } from "./ProgressComponents";
import { ProgressProps, TrainingToLaunch } from "../../../../utils/types/profile";
import TrainingsStats from "./TrainingsStats";

export function Progress({ learningInfo, learningObjectives, handleModeChange }: ProgressProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const [mandatoryOnly, setMandatoryOnly] = useState<boolean>(false);
  const [trainingToLaunch, setTrainingToLaunch] = useState<TrainingToLaunch | undefined>();

  const progressBarRef = useRef<HTMLDivElement>(null);
  const learningPathInfo = useGetLearningPathNumbers(learningInfo, mandatoryOnly);
  const noMandatoryTrainings = learningPathInfo?.mandatoryTrainings === 0 && mandatoryOnly;
  // show progressbar and mandatory checkbox only if LP has CCtrainigns
  const onlyCustomTrainings = !learningInfo?.modules.length && learningInfo?.customContent.length;
  const hasTrainings = (learningInfo && learningInfo?.modules?.length > 0) || (learningInfo && learningInfo?.customContent?.length > 0);

  const handleMandatoryChecked = (value: boolean) => {
    setMandatoryOnly(!!value);

    if (progressBarRef.current) {
      progressBarRef.current.style.animation = "none";
      // Reflow to restart the animation
      void progressBarRef.current.offsetWidth; // Force reflow
      progressBarRef.current.style.animation = ""; // Restore animation
    }
  };

  return (
    <Card
      aria-roledescription="card avatar"
      elevated
      inverted
      className="profileCard"
      id="progress"
      styles={{ height: isMobile ? "fit-content" : "100%" }}
    >
      <Card.Header>
        <Flex gap="gap.medium" styles={{ width: "100%", minHeight: "20%" }}>
          <Flex column styles={{ width: "100%" }}>
            <Header content={t("Profile.LearningPath.title")} className="cardHeadline" />
            {learningInfo?.chosenLearningPathName && <Text content={learningInfo?.chosenLearningPathName} />}
          </Flex>
        </Flex>
      </Card.Header>
      {hasTrainings ? (
        <Flex column styles={{ maxHeight: "70%", width: "100%" }} gap="gap.small">
          {!onlyCustomTrainings && (
            <ProgressBar
              completionRate={learningPathInfo?.completionRate ?? 0}
              progressBarRef={progressBarRef}
              noMandatoryTrainings={noMandatoryTrainings}
            />
          )}
          <Flex
            column
            styles={{ width: "100%", maxHeight: isMobile ? "100%" : "85%" }}
            hAlign="start"
            gap={isMobile ? "gap.small" : "gap.smaller"}
            vAlign="start"
          >
            <Flex gap="gap.medium" vAlign="center" styles={{ width: "100%" }}>
              <Text align="start" content={t("Profile.LearningPath.nextTraining")} size="large" weight="bold" />
              {!onlyCustomTrainings && (
                <Checkbox
                  label={t("Profile.LearningPath.onlyMandatory")}
                  labelPosition="end"
                  onChange={(event) => handleMandatoryChecked(event.currentTarget.ariaChecked === "false")}
                />
              )}
            </Flex>
            <Flex column={isMobile} styles={{ width: "100%", height: "100%" }} gap={isMobile ? "gap.large" : "gap.small"}>
              <TrainingCatalogue
                mandatoryOnly={mandatoryOnly}
                learningObjectives={learningObjectives}
                learningInfo={learningInfo}
                setTrainingToLaunch={setTrainingToLaunch}
                noMandatoryTrainings={noMandatoryTrainings}
              />
              {learningPathInfo && <TrainingsStats learningPathInfo={learningPathInfo} customTrainings={learningInfo?.customContent} />}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Alert content={"This learning path doesn't have any trainings"} />
      )}
      {learningPathInfo === null ? <Loader /> : <></>}
      <StartTrainingDialog trainingToLaunch={trainingToLaunch} handleModeChange={handleModeChange} setTrainingToLaunch={setTrainingToLaunch} />
    </Card>
  );
}
