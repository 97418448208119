export const getWebchatUserProfile = async () => {
  console.log("fetching /webchat-auth/profile");
  const request = await fetch("/webchat-auth/profile");
  const data = await request.json();
  console.log("I have now done that. here is some stuff");
  console.log(request);
  console.log(`proot proot ${JSON.stringify(data)}`);
  // TODO - did this return 200 etc?
  return data;
};
