import React, { useState, useEffect } from "react";
import { Card, Flex, Header, Text, Loader, Alert } from "@fluentui/react-northstar";
import "../../Profile.css";
import { useTranslation } from "react-i18next";
import BadgeElement from "./BadgeElement";
import { useUserData } from "../../../../utils/context/userContext";
import { BadgesProps, ICombinedTraining, TrainingToLaunch } from "../../../../utils/types/profile";
import StartTrainingDialog from "../../../elements/StartTrainingDialog";
import getAllTrainings from "../../../../utils/helpers/getAllTrainings";

export function Badges({ learningInfo, handleModeChange }: BadgesProps) {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const [badgeContent, setBadgeContent] = useState<ICombinedTraining[] | null | undefined>(null);
  const [completedTrainings, setCompletedTrainings] = useState<number | null>(null);
  const [trainingToLaunch, setTrainingToLaunch] = useState<TrainingToLaunch | undefined>();

  const hasTrainings = (learningInfo && learningInfo?.modules?.length > 0) || (learningInfo && learningInfo?.customContent?.length > 0);

  useEffect(() => {
    if (!learningInfo?.modules || !userData?.badgeData) {
      setBadgeContent(undefined);
      return;
    }
    const { ccTrainings, customTrainings } = getAllTrainings(learningInfo);

    // add training completion date to badge
    const trainingBadgesArray = ccTrainings?.map((training) => {
      const completedTraining = userData?.badgeData && userData.badgeData.find((badge) => badge.training === training.dialog && badge.completedOn);
      return {
        ...training,
        completedOn: completedTraining?.completedOn || null,
      };
    });

    const badgeArray = customTrainings.length ? [...trainingBadgesArray, ...customTrainings] : trainingBadgesArray;
    const completedTrainings = badgeArray?.filter((t) => t.completed).length;

    setBadgeContent(badgeArray);
    setCompletedTrainings(completedTrainings);
  }, [learningInfo, userData?.badgeData]);

  return (
    <Card aria-roledescription="card avatar" elevated inverted styles={{ minWidth: "100%" }} id="badges">
      <Card.Header styles={{ width: "100%", height: "10%" }}>
        <Flex gap="gap.medium" styles={{ width: "100%" }}>
          <Flex column styles={{ width: "100%" }}>
            <Header content={t("Profile.Badges.title")} className="cardHeadline" />
            <Flex space="between" styles={{ width: "100%" }}>
              <Flex column gap="gap.small">
                {learningInfo && (
                  <Flex gap="gap.smaller">
                    <Text content={t("Profile.Badges.subTitle") + ":"} weight="bold" />
                    {learningInfo?.chosenLearningPathName && <Text content={learningInfo?.chosenLearningPathName} />}
                  </Flex>
                )}
              </Flex>
              {badgeContent && <Text align="center" content={`${completedTrainings}/${badgeContent?.length}`} weight="bold" size="large" />}
            </Flex>
          </Flex>
        </Flex>
      </Card.Header>
      {hasTrainings ? (
        <Flex styles={{ width: "100%", height: "90%" }}>
          {badgeContent === null ? (
            <Loader />
          ) : (
            <Flex column space="between" gap="gap.small" styles={{ height: "100%" }}>
              <BadgeElement
                trainings={badgeContent}
                setTrainingToLaunch={setTrainingToLaunch}
                lpExpiryDate={learningInfo?.chosenLearningPathTrainingExpiryDate}
              />
              <Flex hAlign="center" vAlign="center" gap="gap.smaller">
                <div style={{ width: "1.2em", height: "1.2em", backgroundColor: "#cc33ff" }}></div>
                <Text content={t("Profile.Badges.mandatoryTraining")} />
              </Flex>
            </Flex>
          )}
        </Flex>
      ) : (
        <Alert content={"This learning path doesn't have any trainings"} />
      )}
      <StartTrainingDialog trainingToLaunch={trainingToLaunch} handleModeChange={handleModeChange} setTrainingToLaunch={setTrainingToLaunch} />
    </Card>
  );
}
