import React, { useState, useEffect, useMemo } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { DropdownValue, LanguagePickerProps } from "../../utils/types/common";
import { OrgLanguages } from "../../utils/types/company";

export function LanguagePicker({
  companyData,
  defaultSelected,
  handleLanguageChange,
  handleLanguageArrayChange,
  disabled,
  inverted,
  fluid,
  mode,
}: LanguagePickerProps) {
  const { t } = useTranslation(["translation"]);
  const [defaultValue, setDefaultValue] = useState<DropdownValue | DropdownValue[] | null | undefined>();
  const [dropDownValues, setDropDownValues] = useState<DropdownValue[]>();

  const orgLanguages = useMemo(
    () =>
      companyData?.orgLanguages && companyData?.orgLanguages?.length > 0
        ? companyData?.orgLanguages
        : [
            {
              languageCode: "en",
              languageName: "English",
            },
          ],
    [companyData?.orgLanguages]
  );

  const supportedLanguages = companyData?.supportedLanguages;
  const multiDropdown = mode === "SetOrgLanguages";
  const languageArray = multiDropdown ? supportedLanguages : orgLanguages;

  const isLanguageSelected = (languageCode: string): boolean => {
    if (Array.isArray(defaultSelected)) {
      return defaultSelected.includes(languageCode);
    }
    return defaultSelected === languageCode;
  };

  const handleDefaults = (dropDownItems: DropdownValue[]): void => {
    if (multiDropdown) {
      const selectedItems = dropDownItems.filter((item) => item.selected) ?? [];
      setDefaultValue(selectedItems?.length > 0 ? selectedItems : null);
    } else {
      const selectedItem = dropDownItems.find((item) => item.selected) ?? null;
      setDefaultValue(selectedItem);
    }
  };

  useEffect(() => {
    if (!languageArray?.length || !defaultSelected) {
      setDefaultValue(null);
      return;
    }
    const dropDownOptions = languageArray.map((language: OrgLanguages) => ({
      header: language.languageName,
      key: language.languageCode,
      selected: isLanguageSelected(language.languageCode),
      active: isLanguageSelected(language.languageCode),
    }));

    handleDefaults(dropDownOptions);
    setDropDownValues(dropDownOptions);
  }, [defaultSelected, languageArray, multiDropdown]);

  function pickLanguage(item: DropdownValue) {
    if (handleLanguageChange && item) {
      handleLanguageChange(item.key);
    }
    return "Language changed";
  }

  function addToSupported(item: DropdownValue) {
    if (defaultSelected && handleLanguageArrayChange) {
      const newLanguageArray = [...defaultSelected, item?.key];
      handleLanguageArrayChange(newLanguageArray);
    }
    return "Language added to supported languages";
  }

  function removeFromSupported(item: DropdownValue) {
    if (Array.isArray(defaultSelected) && handleLanguageArrayChange) {
      const newLanguageArray = defaultSelected.filter((key: string) => key !== item?.key);
      handleLanguageArrayChange(newLanguageArray);
    }
    return "Language removed from supported languages";
  }

  return dropDownValues ? (
    <>
      {multiDropdown ? (
        <Dropdown
          inverted={inverted}
          fluid={fluid}
          multiple
          align="bottom"
          checkable
          items={dropDownValues}
          value={defaultValue}
          defaultValue={defaultValue}
          placeholder={t("LanguagePicker.chooseSupportedLang") ?? ""}
          styles={{ minHeight: "32px" }}
          getA11ySelectionMessage={{
            onAdd: (item) => addToSupported(item as DropdownValue),
            onRemove: (item) => removeFromSupported(item as DropdownValue),
          }}
        />
      ) : (
        <Dropdown
          inverted={inverted}
          fluid={fluid}
          disabled={disabled}
          checkable
          styles={{ width: "100%" }}
          items={dropDownValues}
          value={defaultValue}
          defaultValue={defaultValue}
          placeholder={t("LanguagePicker.chooseLang") ?? ""}
          getA11ySelectionMessage={{
            onAdd: (item) => pickLanguage(item as DropdownValue),
          }}
        />
      )}
    </>
  ) : (
    <></>
  );
}
