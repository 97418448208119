import { ICompany } from "./company";
import { IUserData } from "./user";

export type CyberCoachGroup = {
  groupDesc: string;
  groupId: string;
  groupName: string;
  languageCode?: string;
  chosenGroup?: boolean;
  isOrgDefault?: number;
  userLanguage?: string;
};

export type CyberCoachGroups = {
  groupId: string; // this is actually a number, if changed here, stringify the value in api call
  isOrgDefault: number;
  groupTranslations: CyberCoachGroup[];
};

export type Training = {
  isMandatory: number;
  scenarioId: string;
  scenarioName: string;
  scenarioNameTranslation: string;
  showScenario: number;
};

export type LearningPathModule = {
  moduleId: string;
  moduleName: string;
  moduleNameTranslation: string;
  showModule: number;
  trainings: Training[];
};

export type LearningPathData = {
  groupId: string;
  isOrgDefault: number;
  modules: LearningPathModule[];
};

export type LearningPathInfo = {
  mandatoryTrainings: number;
  modules: number;
  trainings: number;
};

export type DropdownValue = {
  header: string;
  key: string;
  selected?: boolean;
  active?: boolean;
  image?: string;
};

export interface TabProps {
  accessToken: string;
  providerAdmin: any;
  inTeams: any;
  userInfo: any;
  themeString: string;
}

export enum LocationMode {
  PROFILE = "profile",
  ADMIN = "admin",
  CONFIGURE = "configure",
  CYBERCOACH = "cybercoach",
  REPORTADMIN = "reportadmin",
  NOTIFICATIONADMIN = "notificationadmin",
}

// Training type is to differenciate custom and cc traingings on launch
export enum TrainingType {
  TRAINING = "training",
  CUSTOM = "content",
}

export type UpdateUserGroupsFunction = (data: IUserData) => Promise<void>;

export type UserGroup = {
  "@odata.type": string;
  id: string;
};

export interface LanguagePickerProps {
  companyData: ICompany | null | undefined | Partial<ICompany>;
  defaultSelected: string | string[] | undefined;
  handleLanguageChange?: (languageCode: string) => void;
  handleLanguageArrayChange?: (languageCode: string[]) => void;
  disabled?: boolean;
  inverted?: boolean;
  fluid?: boolean;
  mode?: string;
}

export interface LearningPathPickerProps {
  handleLearningPathChange: (groupId: string) => void;
  selectedPath: CyberCoachGroup | CyberCoachGroups | undefined;
  cyberCoachGroups: CyberCoachGroup[] | CyberCoachGroups[] | undefined | null;
  manageMode?: boolean;
}

export interface TonePickerProps {
  handleToneChange?: (key: string) => void;
  defaultSelected: string | undefined;
  disabled?: boolean;
  fluid?: boolean;
  inverted?: boolean;
}

export type AvailableLearningPath = {
  chosenGroup: boolean;
  groupDesc: string;
  groupId: string;
  groupName: string;
  isOrgDefault: number;
  languageCode: string;
  userLanguage: string;
  trainingExpirydt?: string | null;
};

export type TrainingModuleStatistics = {
  mandatoryTrainingAmount: number;
  mandatoryTrainingCompleted: number;
  trainingAmount: number;
  trainingsCompleted: number;
};

export type TrainingModuleSet = {
  completed: boolean;
  dialog: string;
  mandatory: boolean;
  name: string;
  url: string;
  expired?: boolean;
};
export type TrainingModule = {
  id: string;
  name: string;
  moduleSet: TrainingModuleSet[];
  moduleStatistics: TrainingModuleStatistics;
};
export type CustomContent = {
  completed: boolean;
  languageCode: string;
  quizId: number;
  quizIntro: string;
  quizName: string;
  expired?: boolean;
};

export type LearningInfo = {
  userLanguage: string;
  chosenLearningPathName: string;
  chosenLearningPath: number;
  chosenLearningPathTrainingExpiryDate?: string | null;
  availableLearningPaths: AvailableLearningPath[];
  customContent: CustomContent[];
  modules: TrainingModule[];
};

export type LearningPathNumbers = {
  trainings: number;
  completedTrainings: number;
  mandatoryTrainings: number;
  mandatoryCompleted: number;
  completionRate?: number;
};

export type MultiDropDownItem = {
  key: string;
  header: string;
  content: string;
};

export interface HeaderProps {
  isAdmin: boolean;
  inTeams: boolean;
  handleModeChange: (mode: string, command?: string) => void;
  themeString: string;
  mode: string;
  accessToken: string;
}
