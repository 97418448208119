import React from "react";
import { Card, Flex, Header, Text, Avatar, CardHeader, CardBody, QuestionCircleIcon, Tooltip } from "@fluentui/react-northstar";
import "../Profile.css";
import { fetchFromBackend } from "../../lib/makeBackendCall";
import { LanguagePicker } from "../../elements/LanguagePicker";
import { useTranslation } from "react-i18next";
import TonePicker from "../../elements/TonePicker";
import LearningPathPicker from "../../elements/LearningPathPicker";
import getGroupInfo from "../../../utils/helpers/getGroupInfo";
import { useUserData } from "../../../utils/context/userContext";
import { useCompanyData } from "../../../utils/context/companyContext";
import { UserCardProps } from "../../../utils/types/userCard";
import useScreenSize from "../../../utils/hooks/useScreenSize";

function UserCard({ accessToken, cyberCoachGroups, userName, userPicture }: UserCardProps) {
  const { t, i18n } = useTranslation();
  const { companyData } = useCompanyData();
  const { userData, setUserData } = useUserData();
  const { isMobile } = useScreenSize();

  const currentGroup = cyberCoachGroups?.find((group) => group.groupId === userData?.cyberCoachGroup?.groupId);

  async function pickGroup(groupId: string) {
    const groupInfo = getGroupInfo(groupId, cyberCoachGroups);
    await fetchFromBackend(accessToken, "cybercoachGroup/" + groupId, "PUT");
    setUserData({ ...userData, cyberCoachGroup: groupInfo });
  }

  async function pickLanguage(languageCode: string) {
    i18n.changeLanguage(languageCode);
    await fetchFromBackend(accessToken, "user/userLanguage/" + languageCode, "PUT");
    setUserData({ ...userData, userLanguage: languageCode });
  }

  async function pickTone(tone: string) {
    await fetchFromBackend(accessToken, "user/tonePreference/" + tone, "PUT");
    setUserData({ ...userData, tonePreference: tone });
  }

  return (
    <Card aria-roledescription="card avatar" elevated inverted className="profileCard" id="userCard">
      <Card.Header>
        <Flex gap="gap.medium">
          <Avatar image={userPicture} name={userName} size="larger" />
          <Flex column>
            <Header content={t("Profile.UserCard.title")} className="cardHeadline" />
            <Text content={userName} size="small" />
          </Flex>
        </Flex>
      </Card.Header>
      <Flex column vAlign="center" gap="gap.medium">
        <Flex vAlign="center" hAlign="stretch" gap="gap.medium">
          <Flex column gap="gap.medium" styles={{ width: "100%" }}>
            <Card elevated inverted styles={{ width: "100%" }}>
              <CardHeader>
                <Text content={t("Profile.UserCard.settings")} weight="bold" size="large" />
              </CardHeader>
              <CardBody>
                <Flex column gap="gap.small" styles={{ width: isMobile ? "100%" : "90%" }}>
                  <Flex column gap="gap.smaller">
                    <Text weight="bold" content={t("Profile.UserCard.language")} />
                    <Flex space="between" fill gap="gap.small" vAlign="center">
                      <LanguagePicker
                        companyData={companyData}
                        defaultSelected={userData?.userLanguage}
                        handleLanguageChange={pickLanguage}
                        inverted
                        fluid
                      />
                      <div style={{ width: "2rem" }} />
                    </Flex>
                  </Flex>
                  <Flex column gap="gap.smaller">
                    <Text weight="bold" content={t("Profile.UserCard.tonePreference")} />
                    <Flex space="between" gap="gap.small" vAlign="center">
                      <TonePicker defaultSelected={userData?.tonePreference} handleToneChange={pickTone} inverted fluid />
                      <div style={{ width: "2rem" }}>
                        <Tooltip
                          trigger={<QuestionCircleIcon outline />}
                          content={<p dangerouslySetInnerHTML={{ __html: t("Profile.UserCard.tonePreferenceTooltip") ?? "" }} />}
                        />
                      </div>
                    </Flex>
                  </Flex>
                  <Flex column gap="gap.smaller">
                    <Text content={t("Profile.UserCard.learningPath")} weight="bold" />
                    <Flex space="between" gap="gap.small" vAlign="center">
                      <LearningPathPicker cyberCoachGroups={cyberCoachGroups} selectedPath={currentGroup} handleLearningPathChange={pickGroup} />
                      <div style={{ width: "2rem" }}>
                        {currentGroup?.groupDesc && <Tooltip trigger={<QuestionCircleIcon outline />} content={currentGroup.groupDesc} />}
                      </div>
                    </Flex>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default UserCard;
